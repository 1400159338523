<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <v-form
      v-if="Object.keys(info).length"
      ref="form"
      style="display: flex; align-items: center; position: relative"
    >
      <v-row style="flex: 1; margin: 0">
        <template v-for="e of panel">
          <v-col
            v-if="e.visible ? e.visible(info) : true"
            :key="e.field"
            cols="12"
          >
            <span>{{ e.text }}：</span>
            <span :style="e.style ? e.style(info) : {}" class="breakAll">
              {{
                e.format
                  ? e.format(info[e.field])
                  : info[e.field]
                  ? e.field === 'reviewStatusText'
                    ? info[e.field] + '-' + info.handleFlagText
                    : info[e.field]
                  : '-'
              }}
            </span>
          </v-col>
        </template>
        <template v-if="isHandle">
          <v-col cols="12">
            <span>处理建议：</span>
            <v-text-field
              v-model="form.handleRemark"
              :counter="200"
              :rules="[(v) => !v || v.length <= 200 || '最大输入200字符！']"
              placeholder="请输入处理建议"
              solo
              style="margin-top: 8px"
            ></v-text-field>
          </v-col>
          <v-col cols="12" style="cursor: pointer">
            <img
              alt="确定"
              height="40"
              src="@/assets/screen/bj/btn_confirm.png"
              style="margin-right: 15px"
              width="90"
              @click="onSubmit('02')"
            />
            <img
              alt="撤销"
              height="40"
              src="@/assets/screen/bj/btn_revoke.png"
              width="90"
              @click="isSubbranch ? onSubmit('03') : (isIssue = true)"
            />
          </v-col>
        </template>
      </v-row>
      <div
        style="
          width: 960px;
          height: 540px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          position: relative;
        "
      >
        <div
          v-if="info.attachList && info.attachList.length"
          class="attach-filter"
        >
          <span
            v-for="index in info.attachList.length"
            :key="index"
            :class="{ active: index === attachActive }"
            @click="attachActive = index"
            >{{ index }}</span
          >
        </div>
        <img
          v-if="
            !info.attachList ||
            !info.attachList[0] ||
            isImage(info.attachList[attachActive - 1])
          "
          v-lazy="
            `${$imgUrl}${info.attachList && info.attachList[attachActive - 1]}`
          "
          :style="`height: 100%;flex: 1; object-fit: contain; overflow: hidden;cursor:${
            isImage(info.attachList && info.attachList[attachActive - 1])
              ? 'pointer'
              : 'auto'
          }`"
          alt="违规"
          @click="
            info.attachList && info.attachList[attachActive - 1]
              ? $bus.$emit('ImgDialog', {
                  imgUrl: `${$imgUrl}${info.attachList[attachActive - 1]}`,
                })
              : null
          "
        />
        <template v-else>
          <video
            :src="`${$imgUrl}${info.attachList[attachActive - 1]}`"
            controls
            style="flex: 1; object-fit: contain; overflow: hidden; height: 100%"
          ></video>
        </template>
      </div>
      <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <div>数据提交中...</div>
      </v-overlay>
    </v-form>
    <Dialog
      v-if="isIssue"
      v-model="isIssue"
      :options="{
        width: 320,
        title: '提示',
      }"
    >
      <v-row style="margin: 0; text-align: center">
        <v-col cols="12">是否需要下发该预警？</v-col>
        <v-col
          cols="12"
          style="cursor: pointer; display: flex; justify-content: center"
        >
          <div class="btn-box" @click="onSubmit('03', '01')">下发</div>
          <div class="btn-box" @click="onSubmit('03', '02')">不下发</div>
        </v-col>
      </v-row>
    </Dialog>
    <Dialog
      v-if="isMessage"
      v-model="isMessage"
      :options="{
        width: 320,
        title: '提示',
      }"
      @set:value="visible = isErr"
    >
      <div style="color: #d2e2e7; text-align: center">{{ message }}</div>
    </Dialog>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import { assiginDeep, isImage } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';

export default {
  //组件注册
  components: {
    Dialog,
  },
  //组件传值
  props: {
    prefix: {
      type: String,
      default: 'home',
    },
  },
  data() {
    return {
      attachActive: 1,
      isIssue: false,
      isErr: false,
      isMessage: false,
      message: undefined,
      form: {
        remark: undefined,
      },
      overlay: false,
      panel: [
        {
          text: '机构',
          field: 'organizationText',
        },
        {
          text: '位置',
          field: 'locationText',
        },
        {
          text: '检测类型',
          field: 'analysisItemText',
        },
        {
          text: '操作人',
          field: 'operatorName',
        },
        {
          text: '结果',
          field: 'violationFlagText',
          style: (info) => ({
            color: info.violationFlag == '02' ? '#fe1329' : '#2cb7ff',
          }),
        },
        {
          text: '结果描述',
          field: 'description',
        },
        {
          text: '操作完成时间',
          field: 'endTime',
        },
        {
          text: '任务状态',
          field: 'taskStatusText',
        },
      ],
      info: {},
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1320,
          title: '预警处理',
        },
      },
    };
  },
  //计算属性
  computed: {
    ...mapState({
      pageKey: (state) => state.screenStore.pageKey,
      isSubbranch: (state) => state.screenStore.isSubbranch,
    }),
    isHandle() {
      return (
        this.info.isTask != 1 &&
        this.info.handleFlag == '01' &&
        this.info.status == '02'
      );
    },
  },
  //属性监听
  watch: {
    visible() {
      if (this.visible) {
        this.info = {};
        this.form = {
          remark: undefined,
        };
        this.overlay = false;
        this.isIssue = false;
        this.isMessage = false;
        this.isErr = false;
        this.attachActive = 1;

        this.dialogOptions = {
          dialog: {
            width: 1320,
            title: '预警处理',
          },
        };
      }
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    isImage,
    setPanel(type, analysisItemText, handleFlag) {
      const thatModule = type || this.pageKey;
      if (['earlyWarning', 'serviceSupervision'].includes(thatModule)) {
        this.panel = [
          {
            text: '机构',
            field: 'organizationText',
          },
          {
            text: '位置',
            field: 'cameraName',
          },
          {
            text: '监测类型',
            field: 'analysisItemText',
          },
          {
            text: '结果',
            field: 'violationFlagText',
            style: (info) => ({
              color: info.violationFlag == '02' ? '#fe1329' : '#2cb7ff',
            }),
          },
          {
            text: '结果描述',
            field: 'description',
          },
          {
            text: '预警时间',
            field: 'warningTime',
          },
        ];
      } else if (
        [ 'cashSupervision', 'safeAssistant'].includes(
          thatModule
        )
      ) {
        this.panel = [
          {
            text: '机构',
            field: 'organizationText',
          },
          {
            text: '位置',
            field: 'cameraName',
          },
          {
            text: '检测类型',
            field: 'analysisItemText',
          },
          // {
          //   text: '操作人',
          //   field: 'operatorName',
          // },

          {
            text: '结果',
            field: 'violationFlagText',
            style: (info) => ({
              color: info.violationFlag == '02' ? '#fe1329' : '#2cb7ff',
            }),
          },
          {
            text: '结果描述',
            field: 'description',
          },
          {
            text: '预警时间 ',
            field: 'warningTime',
          },
        ];
        if (analysisItemText == '卡把') {
          this.panel.splice(4, 0, {
            text: '金额',
            field: 'amount',
            format: (value) => `${value ? value + '元' : '--'}`,
          });
        }
        if (analysisItemText == '行领导查库') {
          this.panel.splice(4, 0, {
            text: '识别查库人员',
            field: 'inspLeaderName',
          });
        }
      } else if (thatModule === 'transactionRecheck') {
        this.panel = [
          {
            text: '机构',
            field: 'organizationText',
          },
          {
            text: '位置',
            field: 'cameraName',
          },
          {
            text: '监测类型',
            field: 'analysisItemText',
          },
          {
            text: '交易类型',
            field: 'tranCodeText',
          },
          {
            text: '交易流水',
            field: 'tranNo',
          },
          {
            text: '金额',
            field: 'amount',
            format: (value) => `${value ? value : 0}元`,
          },
          {
            text: '操作人',
            field: 'operatorName',
          },
          {
            text: '结果',
            field: 'violationFlagText',
            style: (info) => ({
              color: info.violationFlag == '02' ? '#fe1329' : '#2cb7ff',
            }),
          },
          {
            text: '结果描述',
            field: 'description',
          },
          {
            text: '监测开始时间',
            field: 'startTime',
          },
          {
            text: '监测结束时间',
            field: 'endTime',
          },
          {
            text: '预警时间',
            field: 'warningTime',
          },
        ];
      }
      if (['02', '03'].includes(handleFlag)) {
        const arr = [
          {
            text: '处理建议',
            field: 'handleRemark',
          },
          {
            text: '审核状态',
            field: 'reviewStatusText',
          },
          {
            text: '审核人',
            field: 'reviewName',
          },
          {
            text: '审核时间',
            field: 'updatedTime',
          },
        ];
        this.panel = this.panel.concat(arr);
      }
    },
    openDialog(options = {}) {
      console.log(options.row, options.type);
      this.dialogOptions = assiginDeep(this.dialogOptions, { ...options });
      this.setPanel(
        options.type,
        options.row.analysisItemText,
        options.row.handleFlag
      );
      this.visible = true;
      this.$nextTick(() => {
        if (options.row) {
          this.form.uuid = options.row.uuid;
          // this.info = options.row;
          this.$set(this, 'info', options.row);
          this.$forceUpdate();
          this.dialogOptions.dialog.title += options.row.description
            ? '-' + options.row.description
            : '';
        }
      });
    },
    async onSubmit(handleFlag, dispatchFlag) {
      try {
        if (this.$refs.form && !this.$refs.form.validate()) return;
        this.overlay = true;
        const data = await this.$http.post(
          `/${this.$businessISG}/monitor/home/warning-deal`,
          {
            ...this.form,
            handleFlag,
            dispatchFlag,
            subOrganization: this.isSubbranch ? '01' : '02',
          },
          {
            'Content-Type': 'application/json;charset=UTF-8',
          }
        );
        if (data.code != 200) data.message;
        this.overlay = false;
        this.isErr = false;
        this.message = data.message;
        this.$emit('success');
        this.$bus.$emit(`warning-success-${this.prefix}`);
      } catch (error) {
        this.message = `${error.message ? error.message : error}`;
        this.isErr = true;
        this.overlay = false;
      }
      this.isMessage = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-box {
  width: 90px;
  height: 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #25e3ff;
  border: 1px solid #2cadff;
  box-shadow: #387992 0px -1px 20px 0px inset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:not(&:first-child) {
    margin-left: 8px;
  }
}

.col {
  font-size: 16px;
  font-weight: 400;
  color: #d2e2e7;
}

.breakAll {
  word-break: break-all;
}

.attach-filter {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;

  span {
    padding: 0 10px;
    background: #eeeeee;
    color: #000;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    font-size: 16px;

    &.active {
      color: #fff;
      background: #909399;
    }
  }
}
</style>
