var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":_vm.getInputBoundingClientRect.width,"min-width":"auto","content-class":"screen-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:[
        'value-container',
        {
          boxShadow:
            _vm.boxShadow &&
            (_vm.range ? _vm.formatValue.length == 2 || _vm.value.length == 2 : _vm.value),
          'value-custom': _vm.custom,
        } ],on:{"mouseover":function($event){_vm.isHover = true},"mouseout":function($event){_vm.isHover = false}}},'div',attrs,false),on),[_c('div',{ref:"value",class:[
          'value',
          {
            placeholder: !_vm.formatValue.length,
            action: _vm.range
              ? _vm.formatValue.length == 2 || _vm.value.length == 2
              : _vm.value,
            range: _vm.range,
          } ]},[(_vm.range)?[_vm._l((_vm.isArray(_vm.formatValue) && _vm.formatValue.length == 2
              ? _vm.formatValue
              : _vm.isArray(_vm.value) && _vm.value.length == 2
              ? _vm.value
              : _vm.formatPlaceholder),function(e,i){return [_c('span',{key:i},[_vm._v(_vm._s(e))]),(i == 0)?_c('span',{key:_vm.separator + i},[_vm._v(_vm._s(_vm.separator))]):_vm._e()]})]:[_vm._v(" "+_vm._s(_vm.formatValue.length ? _vm.formatValue : _vm.formatPlaceholder)+" ")]],2),(_vm.allowClear && _vm.isHover && _vm.formatValue.length)?_c('Close',{staticClass:"close",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('set:value', undefined)}}}):_c('v-icon',{staticClass:"suffix",attrs:{"size":"16"}},[_vm._v(" mdi-clock-outline ")])],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-date-picker',{staticStyle:{"margin-top":"8px"},attrs:{"range":_vm.range,"dark":"","locale":"zh-cn","width":_vm.getInputBoundingClientRect.width},model:{value:(_vm.formatValue),callback:function ($$v) {_vm.formatValue=$$v},expression:"formatValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }