<template>
  <v-col :cols="cols" style="display: flex; align-items: center">
    <span
      :style="lableStyle"
      style="font-size: 16px; font-weight: 400; color: #d2e2e7"
    >
      {{ lable }}：
    </span>
    <slot>
      <div class="box"></div>
    </slot>
  </v-col>
</template>

<script>
export default {
  //组件注册
  components: {},
  //组件传值
  props: {
    lable: {
      type: String,
      default: '',
    },
    lableStyle: {
      type: [String, Object],
      default: '',
    },
    cols: {
      type: [String, Number],
      default: 4,
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
  flex: 1;
  min-height: 40px;
  font-size: 16px;
  color: #53bcff;
  font-size: 14px;
  font-weight: 400;
  background: #092e4e;
  border: 1px solid #278ddb;
  border-radius: 2px;
  cursor: pointer;
}
</style>
