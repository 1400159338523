<template>
  <v-menu
    v-model="visible"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :max-width="custom ? 'auto' : getInputBoundingClientRect.width"
    min-width="auto"
    content-class="screen-menu"
    :disabled="disabled"
  >
    <template #activator="{ on, attrs }">
      <div
        :class="[
          'value-container',
          {
            boxShadow: boxShadow && getLable,
            'value-custom': custom,
          },
        ]"
        :style="{
          cursor: disabled ? 'no-drop' : 'pointer',
        }"
        v-bind="attrs"
        v-on="on"
      >
        <div
          ref="value"
          :class="[
            'value',
            {
              placeholder: !getLable,
              action: getLable,
            },
          ]"
          :title="getLable"
        >
          {{ getLable ? getLable : placeholder }}
        </div>
        <v-icon
          v-if="!disabled"
          :class="['extend', { 'extend-action': visible }]"
          size="18"
          >mdi-apple-keyboard-control</v-icon
        >
      </div>
    </template>
    <v-treeview
      :key="key"
      :value="value"
      :active="value"
      :open="getOpenKey"
      activatable
      class="box scrollbar-y"
      :items="options"
      v-bind="replaceFields"
      :style="{ width: custom ? 'auto' : getInputBoundingClientRect.width }"
      @update:active="onChange"
    ></v-treeview>
  </v-menu>
</template>

<script>
import { flatTree } from '@/views/screen/config';
export default {
  //组件注册
  components: {},
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    replaceFields: {
      type: Object,
      default: () => ({
        itemText: 'name',
        itemKey: 'id',
        itemChildren: 'children',
        itemDisabled: 'disabled',
      }),
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    boxShadow: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: Date.now(),
      visible: false,
      inputBoundingClientRect: {},
    };
  },
  //计算属性
  computed: {
    getInputBoundingClientRect() {
      return {
        width:
          this.inputBoundingClientRect.width > 210
            ? `${this.inputBoundingClientRect.width}px`
            : 'auto',
      };
    },
    getLable() {
      let lable = '';
      if (this.value.length) {
        lable = this.treeFlatList
          .filter((e) => this.value.includes(e[this.replaceFields.itemKey]))
          .map((e) => e[this.replaceFields.itemText])
          .toString();
      }
      return lable;
    },
    treeFlatList() {
      return flatTree({
        ...this.replaceFields,
        children: this.options,
      });
    },
    getOpenKey() {
      const openKey = [...this.value];
      let isFloor = false;
      while (openKey.length && !isFloor) {
        const current = this.treeFlatList.find(
          (e) => e[this.replaceFields.itemKey] === openKey[openKey.length - 1]
        );
        if (current && current['parentId'] != 0) {
          openKey.push(current['parentId']);
        } else {
          isFloor = true;
        }
      }
      return openKey;
    },
  },
  //属性监听
  watch: {
    visible() {
      if (this.visible && this.$refs.value) {
        this.inputBoundingClientRect =
          this.$refs.value.parentElement.getBoundingClientRect();
      }
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    onChange(val) {
      if (this.value.length && !val.length) {
        this.$emit('set:value', this.value);
        this.key = Date.now();
      } else {
        this.$emit('set:value', val);
      }
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.value-container {
  flex: 1;
  min-height: 40px;
  background: #092e4e;
  border: 1px solid #278ddb;
  border-radius: 2px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  .value {
    flex: 1;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
  }
  .action {
    color: #53bcff;
  }
  .placeholder {
    color: #699bc6;
  }
  .extend {
    color: #278ddb;
    margin-left: 8px;
    transform: rotate(180deg) translateY(3px);
  }
  .extend-action {
    transform: rotate(0deg) translateY(3px);
  }
}
.box {
  background: #092e4e;
  border: 1px solid #278ddb;
  border-radius: 2px;
  max-height: 290px;
  margin-top: 8px;
  cursor: pointer;
  ::v-deep .v-treeview-node {
    .v-treeview-node__toggle,
    .v-treeview-node__label {
      color: #d2e2e7;
    }
  }
}
</style>
