<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <TableLayout
      ref="TableLayout"
      :columns="columns"
      :query-format="queryFormat"
      :format-global-query="formatGlobalQuery"
      :url="`/${$businessISG}/monitor/serve/serve-page`"
    >
      <template slot="query-form" slot-scope="{ queryParam }">
        <QueryLayout lable="机构名称">
          <SelectTree
            v-model="queryParam.organizationUUID"
            :options="organizationTree"
            :replace-fields="{
              itemText: 'name',
              itemKey: 'uuid',
              itemChildren: 'children',
            }"
            placeholder="请选择机构名称"
          ></SelectTree>
        </QueryLayout>
        <QueryLayout lable="审核状态">
          <Select
            v-model="queryParam.reviewStatus"
            :options="reviewStatus"
            :replace-fields="{
              lable: 'msg',
              value: 'code',
            }"
            placeholder="请选择审核状态"
          ></Select>
        </QueryLayout>
        <QueryLayout lable="违规时间">
          <DatePicker v-model="queryParam.time" range></DatePicker>
        </QueryLayout>
      </template>
      <div
        slot="table-item-action"
        slot-scope="{ row }"
        style="cursor: pointer"
        @click="$refs.EarlyWarningHandleInfoDialog.openDialog({ row })"
      >
        <div style="color: #25c4ff">查看</div>
      </div>
    </TableLayout>
    <EarlyWarningHandleInfoDialog
      ref="EarlyWarningHandleInfoDialog"
      prefix="serve"
      @success="$refs.TableLayout && $refs.TableLayout.getData()"
    ></EarlyWarningHandleInfoDialog>
  </Dialog>
</template>

<script>
import {mapState} from 'vuex';
// import {assiginDeep} from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import TableLayout from '@/views/screen/components/TableLayout.vue';
import QueryLayout from '@/views/screen/components/QueryLayout.vue';
import Select from '@/views/screen/components/Select.vue';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
import EarlyWarningHandleInfoDialog from '@/views/screen/earlyWarning/modules/EarlyWarningHandleInfoDialog.vue';

export default {
  //组件注册
  components: {
    Dialog,
    TableLayout,
    QueryLayout,
    Select,
    SelectTree,
    DatePicker,
    EarlyWarningHandleInfoDialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1500,
          title: '违规预警记录',
        },
      },
      columns: [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
        },
        {
          text: '违规时间',
          value: 'warningTime',
          sortable: false,
          align: 'center',
        },
        /*        {
          text: '操作位置',
          value: 'cameraName',
          sortable: false,
          align: 'center',
        },*/
        {
          text: '违规内容',
          value: 'description',
          sortable: false,
          align: 'center',
        },
        {
          text: '审核状态',
          value: 'reviewStatusText',
          sortable: false,
          align: 'center',
        },
        {
          text: '审核人',
          value: 'reviewName',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
      ],
    };
  },
  //计算属性
  computed: {
    ...mapState({
      organizationTree: (state) => state.screenStore.organizationTree,
      reviewStatus: (state) =>
        state.screenStore.commondict['MonitorDataReviewStatusEnum']
          ? state.screenStore.commondict['MonitorDataReviewStatusEnum']
          : [],
    }),
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    formatGlobalQuery(globalQuery) {
      const query = {
        organizationUUID: [globalQuery.organizationUUID],
        ...this.dialogOptions.fixedParam,

      };
      if (globalQuery.timeStartStr && globalQuery.timeEndStr) {
        query.time = ['timeStartStr', 'timeEndStr'].map(
          (key) => globalQuery[key]
        );
      }

      console.log(query);
      return query;
    },
    queryFormat(query) {
      for (const key in query) {
        if (['organizationUUID'].includes(key) && Array.isArray(query[key])) {
          query[key] = query[key].toString();
        } else if (
          ['time'].includes(key) &&
          Array.isArray(query[key]) &&
          query[key].length == 2
        ) {
          Object.assign(
            query,
            ['StartStr', 'EndStr'].reduce(
              (val, suffix, i) => ({
                ...val,
                [`${key}${suffix}`]: query[key][i] ?? '',
              }),
              {}
            )
          );
          delete query[key];
        }
      }
      if (this.dialogOptions.query)
        query = Object.assign(query, this.dialogOptions.query);
      if (this.dialogOptions.fixedParam)
        query = Object.assign(query, this.dialogOptions.fixedParam);
      for (const queryKey in query) {
        if (!query[queryKey]) {
          delete query[queryKey]
        }
      }
      return query;
    },
    openDialog(options = {}) {
      console.log(options);
      this.dialogOptions = {...this.dialogOptions, ...options}
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
