var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":_vm.custom ? 'auto' : _vm.getInputBoundingClientRect.width,"min-width":"auto","content-class":"screen-menu","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:[
        'value-container',
        {
          boxShadow: _vm.boxShadow && _vm.getLable,
          'value-custom': _vm.custom,
        } ],style:({
        cursor: _vm.disabled ? 'no-drop' : 'pointer',
      })},'div',attrs,false),on),[_c('div',{ref:"value",class:[
          'value',
          {
            placeholder: !_vm.getLable,
            action: _vm.getLable,
          } ],attrs:{"title":_vm.getLable}},[_vm._v(" "+_vm._s(_vm.getLable ? _vm.getLable : _vm.placeholder)+" ")]),(!_vm.disabled)?_c('v-icon',{class:['extend', { 'extend-action': _vm.visible }],attrs:{"size":"18"}},[_vm._v("mdi-apple-keyboard-control")]):_vm._e()],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-treeview',_vm._b({key:_vm.key,staticClass:"box scrollbar-y",style:({ width: _vm.custom ? 'auto' : _vm.getInputBoundingClientRect.width }),attrs:{"value":_vm.value,"active":_vm.value,"open":_vm.getOpenKey,"activatable":"","items":_vm.options},on:{"update:active":_vm.onChange}},'v-treeview',_vm.replaceFields,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }