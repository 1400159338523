var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":_vm.getInputBoundingClientRect.width,"min-width":"auto","content-class":"screen-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:[
        'value-container',
        {
          boxShadow: _vm.boxShadow && _vm.getLable,
          'value-custom': _vm.custom,
        } ],style:(_vm.containerStyle),on:{"mouseover":function($event){_vm.isHover = true},"mouseout":function($event){_vm.isHover = false}}},'div',attrs,false),on),[_c('div',{ref:"value",class:[
          'value',
          {
            placeholder: !_vm.getLable,
            action: _vm.getLable,
          } ]},[_vm._v(" "+_vm._s(_vm.getLable ? _vm.getLable : _vm.placeholder)+" ")]),(_vm.allowClear && _vm.isHover && _vm.getLable)?_c('Close',{staticClass:"close",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('set:value', undefined)}}}):_c('v-icon',{class:['extend', { 'extend-action': _vm.visible }],attrs:{"size":"18"}},[_vm._v("mdi-apple-keyboard-control")])],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"box scrollbar-y",style:({ width: _vm.getInputBoundingClientRect.width })},_vm._l((_vm.options),function(e,i){return _c('div',{key:i,class:[
        'list-item',
        {
          'action-item': _vm.isRadio
            ? e[_vm.replaceFields.value] == _vm.value
            : _vm.value.includes(e[_vm.replaceFields.value]),
        } ],on:{"click":function($event){return _vm.choose(e)}}},[_vm._v(" "+_vm._s(e[_vm.replaceFields.lable])+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }