<template>
  <v-menu
    v-model="visible"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :max-width="getInputBoundingClientRect.width"
    min-width="auto"
    content-class="screen-menu"
  >
    <template #activator="{ on, attrs }">
      <div
        :class="[
          'value-container',
          {
            boxShadow:
              boxShadow &&
              (range ? formatValue.length == 2 || value.length == 2 : value),
            'value-custom': custom,
          },
        ]"
        v-bind="attrs"
        v-on="on"
        @mouseover="isHover = true"
        @mouseout="isHover = false"
      >
        <div
          ref="value"
          :class="[
            'value',
            {
              placeholder: !formatValue.length,
              action: range
                ? formatValue.length == 2 || value.length == 2
                : value,
              range,
            },
          ]"
        >
          <template v-if="range">
            <template
              v-for="(e, i) in isArray(formatValue) && formatValue.length == 2
                ? formatValue
                : isArray(value) && value.length == 2
                ? value
                : formatPlaceholder"
            >
              <span :key="i">{{ e }}</span>
              <span v-if="i == 0" :key="separator + i">{{ separator }}</span>
            </template>
          </template>
          <template v-else>
            {{ formatValue.length ? formatValue : formatPlaceholder }}
          </template>
        </div>
        <Close
          v-if="allowClear && isHover && formatValue.length"
          class="close"
          @click.native.stop="$emit('set:value', undefined)"
        ></Close>
        <v-icon v-else class="suffix" size="16"> mdi-clock-outline </v-icon>
      </div>
    </template>
    <v-date-picker
      v-model="formatValue"
      :range="range"
      dark
      locale="zh-cn"
      style="margin-top: 8px"
      :width="getInputBoundingClientRect.width"
    ></v-date-picker>
  </v-menu>
</template>

<script>
// import moment from 'moment';
import Close from '@/views/screen/components/Close.vue';
export default {
  //组件注册
  components: { Close },
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: [String, Array],
      default: () => [],
    },
    range: {
      type: Boolean,
      default: false,
    },
    separator: {
      type: String,
      default: '至',
    },
    placeholder: {
      type: [String, Array],
      default: '',
    },
    boxShadow: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ranges: [],
      visible: false,
      inputBoundingClientRect: {},
      isHover: false,
    };
  },
  //计算属性
  computed: {
    getInputBoundingClientRect() {
      return {
        width: `${
          this.inputBoundingClientRect.width > 290
            ? this.inputBoundingClientRect.width
            : 290
        }px`,
      };
    },
    formatValue: {
      get() {
        return this.range ? this.ranges : this.value;
      },
      set(val) {
        if (this.range) {
          this.ranges = val;
          if (val.length == 2) {
            this.$emit('set:value', val);
            this.visible = false;
          } else if (this.value.length == 2) {
            this.$emit('set:value', this.value);
          }
        } else {
          this.$emit('set:value', val);
          this.visible = false;
        }
      },
    },
    formatPlaceholder() {
      const placeholdere = this.range ? ['开始时间', '结束时间'] : '请选择日期';
      return this.placeholder ? this.placeholder : placeholdere;
    },
  },
  //属性监听
  watch: {
    visible() {
      if (this.visible) {
        if (this.$refs.value) {
          this.inputBoundingClientRect =
            this.$refs.value.parentElement.getBoundingClientRect();
        }
      } else {
        if (this.range) {
          if (
            Array.isArray(this.value) &&
            this.value.length == 2 &&
            this.ranges.length != 2
          ) {
            this.ranges = this.value;
          }
        }
      }
    },
    value: {
      deep: true,
      handler: function () {
        if (
          this.range &&
          Array.isArray(this.value) &&
          ((this.value.length == 0 && this.ranges.length == 2) ||
            this.value.length == 2)
        ) {
          this.ranges = [...this.value].sort((a, b) => a - b);
        }
      },
      immediate: true,
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    isArray(target) {
      return Object.prototype.toString.call(target) === '[object Array]';
    },
  },
};
</script>

<style lang="scss" scoped>
.value-container {
  flex: 1;
  min-height: 40px;
  background: #092e4e;
  border: 1px solid #278ddb;
  border-radius: 2px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  .value {
    flex: 1;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
  }
  .range {
    display: flex;
    justify-content: space-around;
  }
  .action {
    color: #53bcff;
  }
  .placeholder {
    color: #699bc6;
  }
  .suffix {
    color: #278ddb;
    margin-left: 8px;
  }
  .close {
    opacity: 0;
    margin-left: 8px;
  }
  &:hover .close {
    opacity: 1;
  }
}
</style>
