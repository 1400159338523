<template>
  <v-menu
    v-model="visible"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :max-width="getInputBoundingClientRect.width"
    min-width="auto"
    content-class="screen-menu"
  >
    <template #activator="{ on, attrs }">
      <div
        :class="[
          'value-container',
          {
            boxShadow: boxShadow && getLable,
            'value-custom': custom,
          },
        ]"
        :style="containerStyle"
        v-bind="attrs"
        v-on="on"
        @mouseover="isHover = true"
        @mouseout="isHover = false"
      >
        <div
          ref="value"
          :class="[
            'value',
            {
              placeholder: !getLable,
              action: getLable,
            },
          ]"
        >
          {{ getLable ? getLable : placeholder }}
        </div>
        <Close
          v-if="allowClear && isHover && getLable"
          class="close"
          @click.native.stop="$emit('set:value', undefined)"
        ></Close>
        <v-icon
          v-else
          :class="['extend', { 'extend-action': visible }]"
          size="18"
          >mdi-apple-keyboard-control</v-icon
        >
      </div>
    </template>
    <div
      class="box scrollbar-y"
      :style="{ width: getInputBoundingClientRect.width }"
    >
      <div
        v-for="(e, i) in options"
        :key="i"
        :class="[
          'list-item',
          {
            'action-item': isRadio
              ? e[replaceFields.value] == value
              : value.includes(e[replaceFields.value]),
          },
        ]"
        @click="choose(e)"
      >
        {{ e[replaceFields.lable] }}
      </div>
    </div>
  </v-menu>
</template>

<script>
import Close from '@/views/screen/components/Close.vue';
export default {
  //组件注册
  components: { Close },
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    replaceFields: {
      type: Object,
      default: () => ({
        lable: 'lable',
        value: 'value',
      }),
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    boxShadow: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
    containerStyle: {
      type: [Object, String],
      default: '',
    },
    isRadio: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      inputBoundingClientRect: {},
      isHover: false,
    };
  },
  //计算属性
  computed: {
    getInputBoundingClientRect() {
      return {
        width: this.inputBoundingClientRect.width
          ? `${this.inputBoundingClientRect.width}px`
          : 'auto',
      };
    },
    getLable() {
      const current = this.options.find(
        (e) => e[this.replaceFields.value] == this.value
      );
      return this.isRadio
        ? current
          ? current[this.replaceFields.lable]
          : undefined
        : Array.isArray(this.value) && this.value.length
        ? this.options
            .filter((e) => this.value.includes(e[this.replaceFields.value]))
            .map((e) => e[this.replaceFields.lable])
            .toString()
        : undefined;
    },
  },
  //属性监听
  watch: {
    visible() {
      if (this.visible && this.$refs.value) {
        this.inputBoundingClientRect =
          this.$refs.value.parentElement.getBoundingClientRect();
      }
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    choose(e){
      console.log(e[this.replaceFields.value]);
      this.$emit(
        'set:value',
        this.isRadio
          ? e[this.replaceFields.value]
          : this.value.includes(e[this.replaceFields.value])
          ? this.value.filter((ee) => ee != e[this.replaceFields.value])
          : [...this.value, e[this.replaceFields.value]]
      );
      this.visible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.value-container {
  flex: 1;
  min-height: 40px;
  background: #092e4e;
  border: 1px solid #278ddb;
  border-radius: 2px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  .value {
    flex: 1;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
  }
  .action {
    color: #53bcff;
  }
  .placeholder {
    color: #699bc6;
  }
  .extend {
    color: #278ddb;
    margin-left: 8px;
    transform: rotate(180deg) translateY(3px);
  }
  .extend-action {
    transform: rotate(0deg) translateY(3px);
  }
  .close {
    opacity: 0;
    margin-left: 8px;
  }
  &:hover .close {
    opacity: 1;
  }
}
.box {
  margin-top: 8px;
  background: #092e4e;
  border: 1px solid #278ddb;
  border-radius: 2px;
  max-height: 290px;
  cursor: pointer;
  .list-item {
    padding: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #d2e2e7;
  }
  .action-item {
    background: #10436e;
  }
}
</style>
