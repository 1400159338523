<template>
  <v-row class="screen-table">
    <v-col cols="12">
      <v-row style="display: flex; flex-wrap: wrap; align-items: center">
        <slot name="query-form" :queryParam="queryParam"></slot>
        <v-col
          cols="4"
          style="
            display: flex;
            justify-content: flex-end;
            flex: 1;
            max-width: none;
          "
        >
          <div class="btn-box" @click="getData">
            <v-icon style="color: #25e3ff">mdi-magnify</v-icon>
            搜索
          </div>
          <div class="btn-box" @click="onRest">
            <v-icon style="color: #25e3ff">mdi-autorenew</v-icon>
            重置
          </div>
          <div
            v-if="Object.keys(exportOption).length"
            class="btn-box"
            @click="onExport"
          >
            <v-icon style="color: #25e3ff"
              >mdi-format-vertical-align-bottom</v-icon
            >导出
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table
        dark
        no-data-text="暂无数据"
        :loading="loading"
        loading-text="数据获取中..."
        :headers="columns"
        :items="dataSource"
        disable-pagination
      >
        <template
          v-for="e of columns.filter((e) => e.itemSlots)"
          :slot="`item.${e.value}`"
          slot-scope="{ item }"
        >
          <slot :name="`table-item-${e.value}`" :row="item"></slot>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <v-pagination
        v-model="ipagination.pageNo"
        :length="ipagination.pages"
        :total-visible="ipagination.visible"
      ></v-pagination
    ></v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { easyPoiExcel } from '@/api/exportsExcel';
export default {
  //组件注册
  components: {},
  //组件传值
  props: {
    columns: {
      type: Array,
      default: () => [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作位置',
          value: 'cameraName',
          sortable: false,
          align: 'center',
        },
        {
          text: '任务类型',
          value: 'analysisItemText',
          sortable: false,
          align: 'center',
        },
        {
          text: '任务编号',
          value: 'taskNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '任务名称',
          value: 'taskName',
          sortable: false,
          align: 'center',
        },
        {
          text: '计划完成时间',
          value: 'planCompleteTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '执行状态',
          value: 'status',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
        {
          text: '执行结果',
          value: 'result',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
        {
          text: '完成时间',
          value: 'actualCompleteTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '执行结果描述',
          value: 'remark',
          sortable: false,
          align: 'center',
        },
        {
          text: '执行人',
          value: 'accName',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
      ],
    },
    formatGlobalQuery: {
      type: Function,
      default: () => ({}),
    },
    queryFormat: {
      type: Function,
      default: () => ({}),
    },
    url: {
      type: String,
      default: '',
    },
    exportOption: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      dataSource: [],
      queryParam: {},
      ipagination: {
        pageNo: 1,
        maxResults: 10,
        pages: 0,
        visible: 7,
        total: 0,
      },
    };
  },
  //计算属性
  computed: {
    ...mapState({
      globalQuery: (state) => state.screenStore.globalQuery,
    }),
  },
  //属性监听
  watch: {
    queryParam: {
      deep: true,
      handler() {
        if (this.ipagination.pageNo == 1) {
          this.getData();
        } else {
          this.ipagination.pageNo = 1;
        }
      },
    },
    'ipagination.pageNo': {
      handler: 'getData',
    },
  },
  created() {
    const defaultQuery = this.formatGlobalQuery(this.globalQuery);
    if (Object.keys(defaultQuery).length)
      this.$set(this, 'queryParam', defaultQuery);
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    async getData() {
      try {
        this.loading = true;
        let query = {
          ...this.queryParam,
          pageNo: this.ipagination.pageNo,
          maxResults: this.ipagination.maxResults,
        };
        const queryFormat = this.queryFormat({ ...query });
        if (Object.prototype.toString.call(queryFormat) === '[object Object]') {
          query = queryFormat;
        }
        const data = await this.$http.get(this.url, query);
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        const result = data.data[0];
        this.dataSource = result.records;
        this.ipagination.pages = result.pages;
        this.ipagination.total = result.total;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    onRest() {
      const defaultQuery = this.formatGlobalQuery(this.globalQuery);
      this.queryParam = { ...defaultQuery };
      const last = this.ipagination.pageNo;
      this.ipagination.pageNo = 1;
      if (last != 1) {
        this.getData();
      }
    },
    async onExport() {
      const queryFormat = this.queryFormat(this.queryParam);
      await easyPoiExcel(
        this.exportOption.url,
        this.exportOption.fileName,
        Object.prototype.toString.call(queryFormat) === '[object Object]'
          ? queryFormat
          : this.queryParam
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-box {
  width: 90px;
  height: 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #25e3ff;
  border: 1px solid #2cadff;
  box-shadow: #387992 0px -1px 20px 0px inset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .v-icon {
    margin-right: 6px;
  }
  &:not(&:first-child) {
    margin-left: 6px;
  }
}
</style>
